<template>
  <multiselect
    id="region"
    v-model="selectedRegions"
    select-label=""
    deselect-label=""
    track-by="id"
    label="name"
    :searchable="true"
    :close-on-select="true"
    :show-labels="true"
    :options="suggestions"
    :loading="false"
    :internal-search="false"
    :multiple="true"
    :preserve-search="true"
    :placeholder="searchTerm ? searchTerm : $t('home.searchBarPlaceholder')"
    :showLabels="false"
    @search-change="onSearchChange"
    class="multiselect--region"
    :class="{
      filled: searchTerm,
      'no--selection': selectedRegions === null || selectedRegions.length === 0,
    }"
    :block-keys="['Enter']"
    ref="multiselectRegion"
    :showNoOptions="false"
    :showNoResults="false"
    @open="onOpen"
  >
    <template slot="selection" slot-scope="{ values, remove, isOpen }">
      <span class="region--tag" v-for="(value, index) in values" :key="`region-tag-${index}`">
        <span class="region--tag-content">
          <span>{{ value.name }}</span>
          <span v-if="value.parents">
            <span v-for="(parent, index) in value.parents" :key="`tag-parent-${index}`">
              <span v-if="index < value.parents.length - 1">
                <span>{{ ', ' }}</span>
                <span>{{ parent }}</span>
              </span>
            </span>
          </span>
        </span>
        <button type="button" class="region--remove" @click="remove(value)">
          <img src="@/assets/img/icons/close-3.svg" />
        </button>
      </span>
      <div
        class="region--selection-open"
        v-if="isOpen && values !== null && values.length > 0"
      ></div>
      <span
        class="region--search-term"
        v-if="!isOpen && searchTerm && values !== null && values.length > 0"
      >
        {{ searchTerm }}
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="region--option">
        <div class="region--left">
          <div class="region--name">{{ props.option.name }}</div>
          <div class="region--desc" v-if="props.option.parents">
            <span v-for="(parent, index) in props.option.parents" :key="`parent-${index}`">
              <span>{{ parent }}</span>
              <span v-if="index < props.option.parents.length - 1">{{ ', ' }}</span>
            </span>
          </div>
        </div>
        <div class="region--type">
          {{ $t(`general.region.${props.option.type}`) }}
        </div>
      </div>
    </template>
    <template slot="noResult">
      <div>{{ $t('general.noSearchResults') }}</div>
    </template>
    <template slot="noOptions">
      <div>{{ $t('general.noSearchOptions') }}</div>
    </template>
  </multiselect>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  mixins: [HelperMixin, LodashMixin],
  components: {
    Multiselect,
  },
  props: ['values', 'remove', 'isNotSearch'],
  data: () => ({
    getSuggestionLoading: false,
    suggestions: [],
    debouncedQuery: () => {},
  }),
  computed: {
    ...mapState({}),
    currentKeyword: {
      get() {
        return this.$store.state.v2.search.currentKeyword;
      },
      set(value) {
        this.$store.commit('v2/search/SET_CURRENT_KEYWORD', this.cleanInput(value));
      },
    },
    searchTerm: {
      get() {
        return this.$store.state.v2.search.searchTerm;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SEARCH_TERM', this.cleanInput(value));
      },
    },
    selectedRegions: {
      get() {
        return this.$store.state.v2.search.selectedRegions;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELECTED_REGIONS', value);
        this.$store.commit('v2/search/SET_PAGE', 1);
        this.currentKeyword = null;
        this.searchTerm = null;
        if (this.isNotSearch) {
          this.goToSearch();
        } else {
          this.$emit('forceSearch');
        }
      },
    },
  },
  mounted() {
    this.debouncedQuery = this.debounce(this.getSuggestions, 500);
    this.currentKeyword = this.searchTerm;
    this.initListener();
  },
  methods: {
    initListener() {
      let self = this;

      // get the input by their class and add the event listener
      let classname = document.getElementsByClassName('multiselect__input');
      let element = classname[0];
      element.addEventListener('keydown', function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          // 13 is enter

          // here is where your search method goes
          self.onEnter();
        }
      });
    },
    async getSuggestions(searchQuery) {
      try {
        this.currentKeyword = searchQuery;
        let response = await this.$store.dispatch('v2/search/getSuggestions', {
          query: searchQuery,
        });
        if (response.data !== null) {
          this.suggestions = response.data;
        } else {
          this.suggestions = [];
        }
      } finally {
        this.getSuggestionLoading = false;
      }
    },
    goToSearch() {
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    onSearchChange(searchQuery) {
      this.getSuggestionLoading = true;
      searchQuery = this.cleanInput(searchQuery);
      this.searchTerm = searchQuery;
      this.debouncedQuery(searchQuery);
    },
    onEnter() {
      console.log('ON ENTER PRESSED MULTISELECT REGION');
      this.$refs.multiselectRegion.deactivate();
      this.$store.commit('v2/search/SET_PAGE', 1);
      if (this.isNotSearch) {
        this.goToSearch();
      } else {
        this.$emit('forceSearch');
      }
    },
    onOpen() {
      this.$refs.multiselectRegion.search = this.searchTerm ? this.searchTerm : '';
    },
  },
};
</script>
